@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Core */
@import "./core/base.css";
@import "./core/blacklabel.css";
@import "./core/button.css";
@import "./core/camper-underline.css";
@import "./core/copy.css";
@import "./core/dropdown.css";
@import "./core/fonts/inter/index.css";
@import "./core/fonts/tiempos/index.css";
@import "./core/fonts/camper/index.css";
@import "./core/type.css";
@import "./core/outline.css";
@import "./core/spacing.css";
@import "./core/svg.css";
@import "./core/icon.css";
@import "./core/mask.css";

/* Shared */
@import "./shared/footer/index.css";
@import "./shared/testimonial-carousel/index.css";
@import "./shared/hubspot-forms/index.css";
@import "./shared/hubspot-forms/modifier.css";
@import "./shared/comparison-chart/index.css";
@import "./shared/logos/index.css";
@import "./shared/logos/logo-slider.css";
@import "./shared/sales-toggle/index.css";
@import "./shared/table-of-contents/index.css";
@import "./shared/carousel/index.css";
@import "./shared/marquee/index.css";
@import "./shared/card/index.css";

/* Templates */
@import "./templates/background-video-controls/index.css";
@import "./templates/component-library-masthead/index.css";
@import "./templates/transcript-accordion/index.css";
@import "./templates/product-accordion/index.css";
@import "./templates/ropi-calculator/index.css";
@import "./templates/careers-listing/index.css";
@import "./templates/case-studies/index.css";

/* Campaigns */
@import "./templates/campaigns/hrforhr/index.css";
@import "./templates/campaigns/emerging-culture-creators/index.css";
@import "./templates/campaigns/research_guide_y22_q3/";
@import "./templates/campaigns/research_guide_y22_q3/story-animation-on-scroll/index.css";
@import "./templates/campaigns/research_guide_y22_q3/company-directions/index.css";
@import "./templates/campaigns/research_guide_y22_q3/type-of-company-quiz/index.css";
@import "./templates/campaigns/high-performance/index.css";
