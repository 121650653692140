/* Heatmap backgrounds */
.hp-heatmap-bg {
  background-image: url("media/themes/high-performance/man_spin_heat_map.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hp-heatmap-bg:hover {
  background-image: url("media/themes/high-performance/man_spin_heat_map.gif");
}

/* Circle spinners */

.hp-spinner {
  animation-name: spinner;
  animation: hp-spinner 80s linear infinite;
}

@keyframes hp-spinner {
  to {
    transform: rotate(360deg);
  }
}

/* Report cover animation */

.hp-report-cover {
  @apply transition-all duration-1000;
}

.hp-report-animation .hp-report-cover {
  @apply -rotate-[3deg];
}

.hp-report-bg {
  @apply absolute top-0 right-0 transition-all duration-1000;
}

.hp-report-animation .hp-report-bg {
  @apply rotate-[3deg] -top-[15px] -right-[15px];
}

@screen desktop {
  .hp-report-animation .hp-report-bg {
    @apply -top-[35px] -right-[35px];
  }
}

/* Small report cover animation */

.hp-report-animation-small .hp-report-bg {
  @apply rotate-[3deg] -top-[5px] -right-[5px];
}

@screen desktop {
  .hp-report-animation-small .hp-report-bg {
    @apply -top-[10px] -right-[10px];
  }
}
